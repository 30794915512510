import { theme } from '@care/material-ui-theme';
import { useMediaQuery as useMuiMediaQuery } from '@mui/material';

export const useMobileQuery = () => useMuiMediaQuery(theme.breakpoints.down('md'));

export const useMediaSameAndDown = (breakpoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl') =>
  useMuiMediaQuery(theme.breakpoints.down(breakpoint));

export const useMediaSameAndUp = (breakpoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl') =>
  useMuiMediaQuery(theme.breakpoints.up(breakpoint));
