import Cookies from 'universal-cookie';
import getConfig from 'next/config';
import { HOMEPAY_PROMO_CODE_COOKIE } from '@/constants';
import { NextConfig } from '@/interfaces';

const { publicRuntimeConfig } = getConfig() as NextConfig;
const { CORE_BASE_URL } = publicRuntimeConfig;

export default (promoCode: string) => {
  const cookies = new Cookies();
  const curCookie = cookies.get(HOMEPAY_PROMO_CODE_COOKIE);
  if (curCookie === null || curCookie === undefined) {
    const maxAge = 31 * 24 * 60 * 60;
    const path = '/';
    const domain = CORE_BASE_URL.replace(/^https?:\/\//, '').replace('www.', '');
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + maxAge * 1000);

    cookies.set(HOMEPAY_PROMO_CODE_COOKIE, promoCode, {
      expires: expirationDate,
      maxAge,
      path,
      domain,
    });
  }
};
