import gql from 'graphql-tag';

export interface CoBrandedPartnerInformationInput {
  input: {
    marketingPath: string;
  };
}

export interface CoBrandedPartnerInformationSuccess {
  name: string;
  pricingPlan: string;
}

interface PartnerInformationError {
  message: string;
}

export interface CoBrandedPartnerInformationError {
  errors: PartnerInformationError[];
}

export interface CoBrandedPartnerInformationResponse {
  coBrandedPartnerInformation:
    | CoBrandedPartnerInformationSuccess
    | CoBrandedPartnerInformationError;
}

export const CO_BRANDED_PARTNER_INFORMATION = gql`
  query coBrandedPartnerInformation($input: CoBrandedPartnerInformationInput!) {
    coBrandedPartnerInformation(input: $input) {
      ... on CoBrandedPartnerInformationSuccess {
        name
        pricingPlan
      }
      ... on CoBrandedPartnerInformationError {
        errors {
          message
        }
      }
    }
  }
`;
