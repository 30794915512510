import { Box, Link, Typography } from '@mui/material';
import { theme } from '@care/material-ui-theme';
import { IconIllustrationSmallFlower } from '@care/react-icons';
import { FC } from 'react';
import { AmplitudeEventName, logAnalyticsEvent, PageProperties } from '@/utils/AnalyticsHelper';
import { getEnrollmentUrl } from '@/utils/urlHelper';
import usePartnerInfo from '@/utils/usePartnerInfo';
import setPromoCodeCookie from '@/utils/setPromoCodeCookie';
import { useFeatureFlags } from '@/components/contexts/FeatureFlagsContext';
import { CLIENT_FEATURE_FLAGS } from '@/constants';

const PROMO_CODE = 'FREEDEC22';

interface PromoBannerProps {
  pageProperties: PageProperties;
}

const PromoBanner: FC<PromoBannerProps> = ({ pageProperties }) => {
  const partnerInfo = usePartnerInfo();
  const { isFeatureFlagActive } = useFeatureFlags();
  const isPromoBannerEnabled = isFeatureFlagActive(CLIENT_FEATURE_FLAGS.PROMO_BANNER);

  if (!isPromoBannerEnabled) {
    return null;
  }

  const handlePromoCodeClick = () => {
    setPromoCodeCookie(PROMO_CODE);
    logAnalyticsEvent({
      data: {
        cta_location: 'PromoBanner',
        cta_text: PROMO_CODE,
      },
      name: AmplitudeEventName.ctaInteracted,
      pageProperties,
    });
    window.location.assign(getEnrollmentUrl(undefined, undefined, partnerInfo?.name));
  };

  return (
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: theme.palette.care?.yellow[100],
        display: 'flex',
        justifyContent: 'center',
        minHeight: '41px',
        textAlign: 'center',
      }}
      data-testid="promo-banner">
      <IconIllustrationSmallFlower
        sx={{
          margin: theme.spacing(0.5),
        }}
      />
      <Typography variant="body3">
        Get one month of HomePay FREE with&nbsp;
        <Link role="button" component="a" onClick={handlePromoCodeClick} variant="inherit">
          {PROMO_CODE}
        </Link>
      </Typography>
    </Box>
  );
};

export default PromoBanner;
