import { useLazyQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { head } from 'ramda';
import {
  CoBrandedPartnerInformationInput,
  CoBrandedPartnerInformationResponse,
  CoBrandedPartnerInformationSuccess,
  CO_BRANDED_PARTNER_INFORMATION,
  CoBrandedPartnerInformationError,
} from '@/API/partners';
import setPromoCodeCookie from './setPromoCodeCookie';

export default () => {
  const [fetchPartnerInfo, { data }] = useLazyQuery<
    CoBrandedPartnerInformationResponse,
    CoBrandedPartnerInformationInput
  >(CO_BRANDED_PARTNER_INFORMATION);
  const router = useRouter();

  const partnerInfo = data?.coBrandedPartnerInformation as
    | CoBrandedPartnerInformationSuccess
    | undefined;

  useEffect(() => {
    if (router?.query.marketingPath) {
      fetchPartnerInfo({
        variables: {
          input: {
            marketingPath: head(router?.query.marketingPath as string[])!,
          },
        },
      });
    }
  }, [router?.query, fetchPartnerInfo]);

  useEffect(() => {
    if (partnerInfo && partnerInfo.name) {
      setPromoCodeCookie('BDFREEMONTH');
    }
    if (
      (partnerInfo as unknown as CoBrandedPartnerInformationError)?.errors?.[0].message ===
      '/: Marketing path was not found.'
    ) {
      router?.replace('/404');
    }
  }, [partnerInfo, router, router?.replace]);

  return partnerInfo;
};
